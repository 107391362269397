var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper",
    on: {
      "click": function ($event) {
        _vm.showRoom = false;
      }
    }
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_vm.activeStep == 1 ? _c('div', [_c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "title": "租客房号",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入房号"
                },
                model: {
                  value: _vm.form.oldRoomNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldRoomNo", $$v);
                  },
                  expression: "form.oldRoomNo"
                }
              })];
            },
            proxy: true
          }, {
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticStyle: {
                  "margin-left": "10px"
                },
                on: {
                  "click": _vm.handleSearchRoom
                }
              }, [_vm._v("搜索")])];
            },
            proxy: true
          }], null, false, 3566483842)
        }), _c('van-cell', {
          attrs: {
            "title": "租赁合同日期",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.form.oldRoomContarctTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldRoomContarctTime", $$v);
                  },
                  expression: "form.oldRoomContarctTime"
                }
              })];
            },
            proxy: true
          }], null, false, 2235466647)
        }), _c('van-cell', {
          attrs: {
            "title": "租客姓名",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.form.tenantName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tenantName", $$v);
                  },
                  expression: "form.tenantName"
                }
              })];
            },
            proxy: true
          }], null, false, 2888733515)
        }), _c('van-cell', {
          attrs: {
            "title": "新房源入住日期",
            "is-link": "",
            "value": _vm.form.newRoomStayInTime
          },
          on: {
            "click": function ($event) {
              _vm.newRoomStayInTimeShow = true;
            }
          }
        }), _c('van-action-sheet', {
          model: {
            value: _vm.newRoomStayInTimeShow,
            callback: function ($$v) {
              _vm.newRoomStayInTimeShow = $$v;
            },
            expression: "newRoomStayInTimeShow"
          }
        }, [_c('van-datetime-picker', {
          attrs: {
            "type": "date",
            "title": "选择年月日",
            "min-date": _vm.newRoomMinDate,
            "max-date": _vm.newRoomMaxDate
          },
          on: {
            "cancel": function ($event) {
              _vm.newRoomStayInTimeShow = false;
            },
            "confirm": _vm.newRoomStayInTimeConfirm
          },
          model: {
            value: _vm.newRoomStayInTime,
            callback: function ($$v) {
              _vm.newRoomStayInTime = $$v;
            },
            expression: "newRoomStayInTime"
          }
        })], 1)], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('div', {
          staticClass: "field-box"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("新房源信息")]), _c('div', {
          staticClass: "item-title"
        }), _vm.newRoomList.length ? _c('van-radio-group', {
          staticClass: "room-select",
          attrs: {
            "checked-color": "#E4C477"
          },
          model: {
            value: _vm.newRoomCode,
            callback: function ($$v) {
              _vm.newRoomCode = $$v;
            },
            expression: "newRoomCode"
          }
        }, _vm._l(_vm.newRoomList, function (room, idx) {
          return _c('van-radio', {
            key: room.houseCode,
            class: {
              'mt5': idx > 0
            },
            attrs: {
              "name": room.houseCode,
              "shape": "square"
            }
          }, [_vm._v(" " + _vm._s(room.projectName + ' ' + room.roomNo) + " ")]);
        }), 1) : _c('div', [_vm._v(" 暂无房源信息 ")])], 1)]), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "title": "原房源保证金",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入",
                  "readonly": "",
                  "type": "number"
                },
                on: {
                  "input": _vm.prohibitNegative
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.oldHouseDeposit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldHouseDeposit", $$v);
                  },
                  expression: "form.oldHouseDeposit"
                }
              })];
            },
            proxy: true
          }], null, false, 605652361)
        }), _c('van-cell', {
          attrs: {
            "title": "新房源保证金",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入",
                  "type": "number"
                },
                on: {
                  "input": e => _vm.form.newHouseDeposit = _vm.prohibitNegative(e, 2),
                  "blur": _vm.extraDepositCompute
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.newHouseDeposit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "newHouseDeposit", $$v);
                  },
                  expression: "form.newHouseDeposit"
                }
              })];
            },
            proxy: true
          }], null, false, 3197793650)
        }), _c('van-cell', {
          attrs: {
            "title": "保证金差额",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "readonly": "",
                  "type": "number"
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.extraDeposit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "extraDeposit", $$v);
                  },
                  expression: "form.extraDeposit"
                }
              })];
            },
            proxy: true
          }], null, false, 1387443433)
        }), _c('van-cell', {
          attrs: {
            "title": "新房源每月综合服务费",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入",
                  "type": "number"
                },
                on: {
                  "input": e => _vm.form.newRoomRentFeeMonth = _vm.prohibitNegative(e, 2)
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.newRoomRentFeeMonth,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "newRoomRentFeeMonth", $$v);
                  },
                  expression: "form.newRoomRentFeeMonth"
                }
              })];
            },
            proxy: true
          }], null, false, 480914706)
        }), _c('van-cell', {
          attrs: {
            "title": "新房源电表度数",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入"
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("度")])];
                  },
                  proxy: true
                }], null, false, 2443981841),
                model: {
                  value: _vm.form.newRoomElectricQuantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "newRoomElectricQuantity", $$v);
                  },
                  expression: "form.newRoomElectricQuantity"
                }
              })];
            },
            proxy: true
          }], null, false, 3308267524)
        }), _c('van-cell', {
          attrs: {
            "title": "新房源电表抄表时间",
            "is-link": "",
            "value": _vm.form.newRoomElectricMeterTime
          },
          on: {
            "click": function ($event) {
              _vm.newRoomElectricMeterTimeShow = true;
            }
          }
        }), _c('van-action-sheet', {
          model: {
            value: _vm.newRoomElectricMeterTimeShow,
            callback: function ($$v) {
              _vm.newRoomElectricMeterTimeShow = $$v;
            },
            expression: "newRoomElectricMeterTimeShow"
          }
        }, [_c('van-datetime-picker', {
          attrs: {
            "type": "datetime",
            "title": "选择年月日",
            "min-date": _vm.minDate1,
            "max-date": _vm.maxDate1
          },
          on: {
            "cancel": function ($event) {
              _vm.newRoomElectricMeterTimeShow = false;
            },
            "confirm": _vm.newRoomElectricMeterTimeConfirm
          },
          model: {
            value: _vm.newRoomElectricMeterTime,
            callback: function ($$v) {
              _vm.newRoomElectricMeterTime = $$v;
            },
            expression: "newRoomElectricMeterTime"
          }
        })], 1), _c('van-cell', {
          attrs: {
            "title": "新房源水表度数",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入"
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("吨")])];
                  },
                  proxy: true
                }], null, false, 2346929567),
                model: {
                  value: _vm.form.newRoomWaterQuantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "newRoomWaterQuantity", $$v);
                  },
                  expression: "form.newRoomWaterQuantity"
                }
              })];
            },
            proxy: true
          }], null, false, 1442038805)
        }), _c('van-cell', {
          attrs: {
            "title": "新房源水表抄表时间",
            "is-link": "",
            "value": _vm.form.newRoomWaterMeterTime
          },
          on: {
            "click": function ($event) {
              _vm.newRoomWaterMeterTimeShow = true;
            }
          }
        }), _c('van-action-sheet', {
          model: {
            value: _vm.newRoomWaterMeterTimeShow,
            callback: function ($$v) {
              _vm.newRoomWaterMeterTimeShow = $$v;
            },
            expression: "newRoomWaterMeterTimeShow"
          }
        }, [_c('van-datetime-picker', {
          attrs: {
            "type": "datetime",
            "title": "选择年月日",
            "min-date": _vm.minDate1,
            "max-date": _vm.maxDate1
          },
          on: {
            "cancel": function ($event) {
              _vm.newRoomWaterMeterTimeShow = false;
            },
            "confirm": _vm.newRoomWaterMeterTimeConfirm
          },
          model: {
            value: _vm.newRoomWaterMeterTime,
            callback: function ($$v) {
              _vm.newRoomWaterMeterTime = $$v;
            },
            expression: "newRoomWaterMeterTime"
          }
        })], 1)], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('div', {
          staticClass: "field-box"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("验房信息")])]), _c('van-cell', {
          attrs: {
            "title": "资产破损赔偿费用",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "type": "number",
                  "placeholder": "请输入"
                },
                on: {
                  "input": e => _vm.form.damagesFee = _vm.prohibitNegative(e, 2)
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.damagesFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "damagesFee", $$v);
                  },
                  expression: "form.damagesFee"
                }
              })];
            },
            proxy: true
          }], null, false, 618965714)
        }), _c('van-field', {
          attrs: {
            "label": "备注",
            "placeholder": "请输入",
            "input-align": "right"
          },
          model: {
            value: _vm.form.damagesFeeRemark,
            callback: function ($$v) {
              _vm.$set(_vm.form, "damagesFeeRemark", $$v);
            },
            expression: "form.damagesFeeRemark"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "额外增值服务费用",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "type": "number",
                  "placeholder": "请输入"
                },
                on: {
                  "input": e => _vm.form.otherFee = _vm.prohibitNegative(e, 2)
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.otherFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "otherFee", $$v);
                  },
                  expression: "form.otherFee"
                }
              })];
            },
            proxy: true
          }], null, false, 1540855250)
        }), _c('van-field', {
          attrs: {
            "label": "备注",
            "placeholder": "请输入",
            "input-align": "right"
          },
          model: {
            value: _vm.form.otherFeeRemark,
            callback: function ($$v) {
              _vm.$set(_vm.form, "otherFeeRemark", $$v);
            },
            expression: "form.otherFeeRemark"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "保洁费用",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "type": "number",
                  "placeholder": "请输入"
                },
                on: {
                  "input": e => _vm.form.cleanFee = _vm.prohibitNegative(e, 2)
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.cleanFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cleanFee", $$v);
                  },
                  expression: "form.cleanFee"
                }
              })];
            },
            proxy: true
          }], null, false, 732747794)
        }), _c('van-field', {
          attrs: {
            "label": "备注",
            "placeholder": "请输入",
            "input-align": "right"
          },
          model: {
            value: _vm.form.cleanFeeRemark,
            callback: function ($$v) {
              _vm.$set(_vm.form, "cleanFeeRemark", $$v);
            },
            expression: "form.cleanFeeRemark"
          }
        }), _c('div', {
          staticClass: "field-box"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("水电表度数")])]), _c('van-cell', {
          attrs: {
            "title": "原房源当前电表度数",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入"
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("度")])];
                  },
                  proxy: true
                }], null, false, 2443981841),
                model: {
                  value: _vm.form.oldRoomElectricQuantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldRoomElectricQuantity", $$v);
                  },
                  expression: "form.oldRoomElectricQuantity"
                }
              })];
            },
            proxy: true
          }], null, false, 3263613151)
        }), _c('van-cell', {
          attrs: {
            "title": "原房源当前电表抄表时间",
            "is-link": "",
            "value": _vm.form.oldRoomElectricMeterTime,
            "title-style": "flex:1.3;"
          },
          on: {
            "click": function ($event) {
              _vm.oldRoomElectricMeterTimeShow = true;
            }
          }
        }), _c('van-action-sheet', {
          model: {
            value: _vm.oldRoomElectricMeterTimeShow,
            callback: function ($$v) {
              _vm.oldRoomElectricMeterTimeShow = $$v;
            },
            expression: "oldRoomElectricMeterTimeShow"
          }
        }, [_c('van-datetime-picker', {
          attrs: {
            "type": "datetime",
            "title": "选择年月日",
            "min-date": _vm.minDate1,
            "max-date": _vm.maxDate1
          },
          on: {
            "cancel": function ($event) {
              _vm.oldRoomElectricMeterTimeShow = false;
            },
            "confirm": _vm.oldRoomElectricMeterTimeConfirm
          },
          model: {
            value: _vm.oldRoomElectricMeterTime,
            callback: function ($$v) {
              _vm.oldRoomElectricMeterTime = $$v;
            },
            expression: "oldRoomElectricMeterTime"
          }
        })], 1), _c('van-cell', {
          attrs: {
            "title": "原房源当前水表度数",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入"
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("吨")])];
                  },
                  proxy: true
                }], null, false, 2346929567),
                model: {
                  value: _vm.form.oldRoomWaterQuantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldRoomWaterQuantity", $$v);
                  },
                  expression: "form.oldRoomWaterQuantity"
                }
              })];
            },
            proxy: true
          }], null, false, 2958068718)
        }), _c('van-cell', {
          attrs: {
            "title": "原房源当前水表抄表时间",
            "is-link": "",
            "value": _vm.form.oldRoomWaterMeterTime,
            "title-style": "flex:1.3;"
          },
          on: {
            "click": function ($event) {
              _vm.oldRoomWaterMeterTimeShow = true;
            }
          }
        }), _c('van-action-sheet', {
          model: {
            value: _vm.oldRoomWaterMeterTimeShow,
            callback: function ($$v) {
              _vm.oldRoomWaterMeterTimeShow = $$v;
            },
            expression: "oldRoomWaterMeterTimeShow"
          }
        }, [_c('van-datetime-picker', {
          attrs: {
            "type": "datetime",
            "title": "选择年月日",
            "min-date": _vm.minDate1,
            "max-date": _vm.maxDate1
          },
          on: {
            "cancel": function ($event) {
              _vm.oldRoomWaterMeterTimeShow = false;
            },
            "confirm": _vm.oldRoomWaterMeterTimeConfirm
          },
          model: {
            value: _vm.oldRoomWaterMeterTime,
            callback: function ($$v) {
              _vm.oldRoomWaterMeterTime = $$v;
            },
            expression: "oldRoomWaterMeterTime"
          }
        })], 1), _c('van-row', {
          staticClass: "upload-container"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_c('div', {
          staticClass: "uploader-col"
        }, [_c('div', [_vm._v("电表图片")]), _c('van-uploader', {
          attrs: {
            "max-count": "1",
            "after-read": file => _vm.afterFileRead(file, 'oldRoomElectricMeterImgUrl')
          },
          model: {
            value: _vm.oldRoomElectricMeterImg,
            callback: function ($$v) {
              _vm.oldRoomElectricMeterImg = $$v;
            },
            expression: "oldRoomElectricMeterImg"
          }
        })], 1)]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_c('div', {
          staticClass: "uploader-col"
        }, [_c('div', [_vm._v("水表图片")]), _c('van-uploader', {
          attrs: {
            "max-count": "1",
            "after-read": file => _vm.afterFileRead(file, 'oldRoomWaterMeterImgUrl')
          },
          model: {
            value: _vm.oldRoomWaterMeterImg,
            callback: function ($$v) {
              _vm.oldRoomWaterMeterImg = $$v;
            },
            expression: "oldRoomWaterMeterImg"
          }
        })], 1)])], 1)], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('div', {
          staticClass: "field-box"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("原房源水电明细")])]), _c('van-cell', {
          attrs: {
            "title": "签约电表抄表",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入",
                  "readonly": ""
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("度")])];
                  },
                  proxy: true
                }], null, false, 2443981841),
                model: {
                  value: _vm.searchedRoom.electricQuantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchedRoom, "electricQuantity", $$v);
                  },
                  expression: "searchedRoom.electricQuantity"
                }
              })];
            },
            proxy: true
          }], null, false, 717607921)
        }), _c('van-cell', {
          attrs: {
            "title": "签约水表抄表",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入",
                  "readonly": ""
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("吨")])];
                  },
                  proxy: true
                }], null, false, 2346929567),
                model: {
                  value: _vm.searchedRoom.waterQuantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchedRoom, "waterQuantity", $$v);
                  },
                  expression: "searchedRoom.waterQuantity"
                }
              })];
            },
            proxy: true
          }], null, false, 1340610176)
        }), _c('van-cell', {
          attrs: {
            "title": "已收水费",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入",
                  "type": "number"
                },
                on: {
                  "input": e => _vm.form.oldRoomWaterFee = _vm.prohibitNegative(e, 2),
                  "blur": _vm.waterFeeLeftCompute
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.oldRoomWaterFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldRoomWaterFee", $$v);
                  },
                  expression: "form.oldRoomWaterFee"
                }
              })];
            },
            proxy: true
          }], null, false, 1165010384)
        }), _c('van-cell', {
          attrs: {
            "title": "应收水费",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入",
                  "type": "number"
                },
                on: {
                  "input": e => _vm.form.oldRoomWaterFeeShould = _vm.prohibitNegative(e, 2),
                  "blur": _vm.waterFeeLeftCompute
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.oldRoomWaterFeeShould,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldRoomWaterFeeShould", $$v);
                  },
                  expression: "form.oldRoomWaterFeeShould"
                }
              })];
            },
            proxy: true
          }], null, false, 3998091312)
        }), _c('van-cell', {
          attrs: {
            "title": "水费结余",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "readonly": "",
                  "type": "number"
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.waterFeeLeft,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "waterFeeLeft", $$v);
                  },
                  expression: "form.waterFeeLeft"
                }
              })];
            },
            proxy: true
          }], null, false, 2009874027)
        }), _c('van-cell', {
          attrs: {
            "title": "已收电费",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入",
                  "type": "number"
                },
                on: {
                  "input": e => _vm.form.oldRoomElectricFee = _vm.prohibitNegative(e, 2),
                  "blur": _vm.electricFeeLeftCompute
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.oldRoomElectricFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldRoomElectricFee", $$v);
                  },
                  expression: "form.oldRoomElectricFee"
                }
              })];
            },
            proxy: true
          }], null, false, 3432956102)
        }), _c('van-cell', {
          attrs: {
            "title": "应收电费",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "placeholder": "请输入",
                  "type": "number"
                },
                on: {
                  "input": e => _vm.form.oldRoomElectricFeeShould = _vm.prohibitNegative(e, 2),
                  "blur": _vm.electricFeeLeftCompute
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.oldRoomElectricFeeShould,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldRoomElectricFeeShould", $$v);
                  },
                  expression: "form.oldRoomElectricFeeShould"
                }
              })];
            },
            proxy: true
          }], null, false, 1448571974)
        }), _c('van-cell', {
          attrs: {
            "title": "电费结余",
            "title-style": "flex:1;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-field', {
                staticStyle: {
                  "padding": "0 0"
                },
                attrs: {
                  "input-align": "right",
                  "type": "number"
                },
                scopedSlots: _vm._u([{
                  key: "right-icon",
                  fn: function () {
                    return [_c('div', [_vm._v("元")])];
                  },
                  proxy: true
                }], null, false, 842794228),
                model: {
                  value: _vm.form.electricFeeLeft,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "electricFeeLeft", $$v);
                  },
                  expression: "form.electricFeeLeft"
                }
              })];
            },
            proxy: true
          }], null, false, 2893264461)
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('div', {
          staticClass: "field-box"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("换房原因")]), _c('div', {
          staticClass: "reason"
        }, [_c('van-radio-group', {
          attrs: {
            "icon-size": "22px",
            "checked-color": "#CEA370",
            "direction": "horizontal"
          },
          model: {
            value: _vm.form.exchangeRoomCause,
            callback: function ($$v) {
              _vm.$set(_vm.form, "exchangeRoomCause", $$v);
            },
            expression: "form.exchangeRoomCause"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": "2"
          }
        }, [_vm._v("客户原因")]), _c('van-radio', {
          attrs: {
            "name": "1"
          }
        }, [_vm._v("项目原因")])], 1)], 1)])])], 1) : _vm._e(), _vm.activeStep == 2 ? _c('div', [_c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "title": "租客姓名",
            "title-style": "flex:0.5;",
            "value": _vm.roomInfo.tenantName
          }
        }), _c('van-cell', {
          attrs: {
            "title": "租赁合同日期",
            "title-style": "flex: 1;",
            "value": `${_vm.roomInfo.oldRoomStartDate}-${_vm.roomInfo.oldRoomEndDate}`
          }
        }), _c('van-cell', {
          attrs: {
            "title": "原房源",
            "title-style": "flex:0.5;",
            "value": _vm.roomInfo.oldRoomNo
          }
        }), _c('van-cell', {
          attrs: {
            "value": _vm.roomInfo.oldHouseDeposit,
            "value-class": _vm.calcValueColor(_vm.roomInfo.oldHouseDeposit),
            "title": "每月保证金",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.oldHouseDeposit)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 2409671388)
        }), _c('van-cell', {
          attrs: {
            "title": "每月综合服务费",
            "title-style": "flex:0.5;",
            "value-class": "color_gold",
            "value": _vm.roomInfo.oldRoomRentPrice
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticClass: "color_gold"
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 2287872112)
        }), _c('van-cell', {
          attrs: {
            "title": "新房源",
            "title-style": "flex:0.5;",
            "value": _vm.roomInfo.newRoomNo
          }
        }), _c('van-cell', {
          attrs: {
            "value": _vm.roomInfo.newHouseDeposit,
            "value-class": _vm.calcValueColor(_vm.roomInfo.newHouseDeposit),
            "title": "每月保证金",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.newHouseDeposit)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 1962944167)
        }), _c('van-cell', {
          attrs: {
            "title": "每月综合服务费",
            "title-style": "flex:0.5;",
            "value-class": "color_gold",
            "value": (Number(_vm.roomInfo.newRoomRentFeeMonth) + Number(_vm.roomInfo.newRoomServiceFeeMonth)).toFixed(2)
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticClass: "color_gold"
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 2287872112)
        }), _c('van-cell', {
          attrs: {
            "title": "新房源入住日期",
            "title-style": "flex:0.5;",
            "value": _vm.roomInfo.newRoomStayInTime
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('div', {
          staticClass: "field-box"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("换房信息明细")])]), _c('van-cell', {
          attrs: {
            "title": "原房源结余综合服务费",
            "title-style": "flex:0.5;",
            "value-class": _vm.calcValueColor(_vm.roomInfo.balanceFee),
            "value": _vm.roomInfo.balanceFee
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.balanceFee)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 521561647)
        }), _c('van-cell', {
          attrs: {
            "value": _vm.roomInfo.extraDeposit,
            "value-class": "color_red",
            "title": "保证金差额",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticClass: "color_red"
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3151629123)
        }), _c('van-cell', {
          attrs: {
            "title": "资产破损配赔偿费",
            "title-style": "flex:1;",
            "value-class": "color_red",
            "value": `-${_vm.roomInfo.damagesFee}`
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticClass: "color_red"
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3151629123)
        }), _c('van-cell', {
          attrs: {
            "title": "额外增值服务费",
            "title-style": "flex:0.5;",
            "value-class": "color_red",
            "value": `-${_vm.roomInfo.otherFee}`
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticClass: "color_red"
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3151629123)
        }), _c('van-cell', {
          attrs: {
            "title": "保洁费",
            "title-style": "flex:0.5;",
            "value-class": "color_red",
            "value": `-${_vm.roomInfo.cleanFee}`
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticClass: "color_red"
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3151629123)
        }), _c('van-cell', {
          attrs: {
            "value": _vm.roomInfo.oldRoomWaterFee,
            "value-class": _vm.calcValueColor(_vm.roomInfo.oldRoomWaterFee),
            "title": "已收水费",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.oldRoomWaterFee)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3817286692)
        }), _c('van-cell', {
          attrs: {
            "value": _vm.roomInfo.oldRoomWaterFeeShould,
            "value-class": _vm.calcValueColor(_vm.roomInfo.oldRoomWaterFeeShould),
            "title": "应收水费",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.oldRoomWaterFeeShould)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 170528781)
        }), _c('van-cell', {
          attrs: {
            "title": "水费结余",
            "title-style": "flex:0.5;",
            "value-class": _vm.calcValueColor(_vm.roomInfo.waterFeeLeft),
            "value": _vm.roomInfo.waterFeeLeft
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.waterFeeLeft)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 4060448711)
        }), _c('van-cell', {
          attrs: {
            "value": _vm.roomInfo.oldRoomElectricFee,
            "value-class": _vm.calcValueColor(_vm.roomInfo.oldRoomElectricFee),
            "title": "已收电费",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.oldRoomElectricFee)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 257308690)
        }), _c('van-cell', {
          attrs: {
            "value": _vm.roomInfo.oldRoomElectricFeeShould,
            "value-class": _vm.calcValueColor(_vm.roomInfo.oldRoomElectricFeeShould),
            "title": "应收电费",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.oldRoomElectricFeeShould)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 2111819995)
        }), _c('van-cell', {
          attrs: {
            "value": _vm.roomInfo.electricFeeLeft,
            "value-class": _vm.calcValueColor(_vm.roomInfo.electricFeeLeft),
            "title": "电费结余",
            "title-style": "flex:0.5;"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.electricFeeLeft)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 544114577)
        }), _c('van-cell', {
          attrs: {
            "title": "换房违约金",
            "title-style": "flex:0.5;",
            "value-class": "color_red",
            "value": _vm.roomInfo.exchangeRuleFee ? `-${_vm.roomInfo.exchangeRuleFee}` : 0
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticClass: "color_red"
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3151629123)
        }), _c('van-cell', {
          attrs: {
            "title": "换房服务费",
            "title-style": "flex:0.5;",
            "value-class": "color_red",
            "value": _vm.roomInfo.exchangeServiceFee ? `-${_vm.roomInfo.exchangeServiceFee}` : 0
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticClass: "color_red"
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3151629123)
        }), _c('van-cell', {
          attrs: {
            "title": "新房源当月应收综合服务费",
            "title-style": "flex:1.2;",
            "value-class": "color_red",
            "value": `-${_vm.roomInfo.firstMonthPayAmount}`
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                staticClass: "color_red"
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3151629123)
        }), _c('van-cell', {
          attrs: {
            "title": "剩余金额",
            "title-style": "flex:0.5;",
            "value-class": _vm.calcValueColor(_vm.roomInfo.remainingFee),
            "value": _vm.roomInfo.remainingFee
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', {
                class: _vm.calcValueColor(_vm.roomInfo.remainingFee)
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 1472528469)
        })], 1), _vm.roomInfo.isCanSignOnline || _vm.$route.query.id ? _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('div', {
          staticClass: "field-box"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("请选择租户换房合同签署方式")]), _c('div', {
          staticClass: "reason"
        }, [_c('van-radio-group', {
          attrs: {
            "icon-size": "22px",
            "checked-color": "#CEA370",
            "disabled": !!_vm.$route.query.id
          },
          model: {
            value: _vm.form.signType,
            callback: function ($$v) {
              _vm.$set(_vm.form, "signType", $$v);
            },
            expression: "form.signType"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": 1
          }
        }, [_vm._v("租户线上签署")]), _c('van-radio', {
          staticStyle: {
            "margin-top": "5px"
          },
          attrs: {
            "name": 2
          }
        }, [_vm._v("租户线下签署")])], 1)], 1)])]) : _vm._e()], 1) : _vm._e()];
      },
      proxy: true
    }, !_vm.$route.query.id ? {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-item cyui-actionbar-btns"
        }, [_vm.activeStep == 2 ? _c('button', {
          staticClass: "cyui-btn cyui-btn-info cyui-btn-block",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              _vm.activeStep = 1;
            }
          }
        }, [_vm._v(" 返回上一步 ")]) : _vm._e(), _vm.activeStep == 2 ? _c('button', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.handleSubmit
          }
        }, [_vm._v(" 确认提交 ")]) : _vm._e(), _vm.activeStep == 1 ? _c('button', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.handleApply
          }
        }, [_vm._v(" 下一步 ")]) : _vm._e()])])];
      },
      proxy: true
    } : null], null, true)
  }), _vm.showRoom ? _c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "100px",
      "left": "0",
      "color": "#323233",
      "z-index": "999",
      "width": "100vw",
      "padding": "0 30px"
    }
  }, [_c('van-cell-group', {
    staticStyle: {
      "box-shadow": "0 0 5px #888888"
    },
    attrs: {
      "inset": ""
    }
  }, [_vm._l(_vm.roomList, function (item, index) {
    return _c('van-cell', {
      key: index,
      attrs: {
        "title": item.tenantName
      },
      on: {
        "click": function ($event) {
          return _vm.handleSelectRoom(item);
        }
      }
    });
  })], 2)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };