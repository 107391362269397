
  import common from '@mixins/common'
  import filters from '@filters/filters'
  import { formatPx2Rem } from '@utils/index'
  import { isEmpty, dateFilter, prohibitNegative } from '@utils/utils'
  import { getLastRentAPI, getHouseListAPI, calculateFeeAPI, submitAPI, getDetailAPI, getQueryWaterElectricityAPI } from '@api/roomChange'
  import imageCompression from 'browser-image-compression'
  import * as assetsApi from '@api/assets'
  import { mapGetters } from 'vuex'
  import { debounce } from 'lodash'
  import dayjs from 'dayjs'

  export default {
    name: 'RoomChangeCreate',
    mixins: [common, filters],
    components: {},
    data() {
      return {
        activeStep: 1,
        roomInfo: {},
        headerTitle: this.$route.query.id ? '换房详情' : '提交换房申请',
        isLoading: false, // 是否在加载数据
        isRefreshPage: false, // 是否需要刷新页面
        noDataMessage: '', // 列表为空时的提示文案
        detailInfo: {}, // 详情信息
        roomList: [],
        newRoomCode: '',
        newRoomList: [],
        showRoom: true,
        roomSearchText: '',
        roomNo: '',
        roomUser: '',
        roomUserDate: '',
        contractId: '',
        mobile: '',
        bankName: '',
        bankAccount: '',
        bankDeposit: '',
        otherReason: '',
        date1: '',
        date2: '',
        form: {
          exchangeRoomCause: '2',
          signType: 2,
          extraDeposit: 0,
          waterFeeLeft: 0,
          electricFeeLeft: 0
        },
        submitForm: {},
        searchedRoom: {},
        newRoomMinDate: new Date(),
        newRoomMaxDate: new Date(),
        minDate1: new Date('2022-01-01'),
        maxDate1: new Date(),
        minDate2: new Date(),
        maxDate2: new Date(2099, 12, 31),
        newRoomStayInTime: new Date(),
        newRoomStayInTimeShow: false,
        newRoomElectricMeterTime: new Date(),
        newRoomElectricMeterTimeShow: false,
        newRoomWaterMeterTime: new Date(),
        newRoomWaterMeterTimeShow: false,
        oldRoomElectricMeterTime: new Date(),
        oldRoomElectricMeterTimeShow: false,
        oldRoomWaterMeterTime: new Date(),
        oldRoomWaterMeterTimeShow: false,
        // 图片
        oldRoomElectricMeterImg: [],
        oldRoomWaterMeterImg: []
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    methods: {
      prohibitNegative,
      formatPx2Rem,
      // 日期格式化
      dateFilter,
      // 空字段过滤
      fieldEmptyFilter(data, returnEmpty = '无') {
        return !isEmpty(data) ? data : returnEmpty
      },
      // 字母数字过滤
      formatterField(value) {
        return value.replace(/[^\u4E00-\u9FA5]/g, '')
      },
      // 只能输入数字字母
      formatterField2(value) {
        return value.replace(/[^\w\.\/]/ig, '')
      },
      calcValueColor(val) {
        if (parseInt(val) < 0) {
          return 'color_red'
        } else {
          return 'color_gold'
        }
      },
      getDetail(id) {
        getDetailAPI({ id }).then(res => {
          // console.log(res)
          const result = res.data
          if (result.code === '10000') {
            this.roomInfo = result.data
            this.form.signType = this.roomInfo.signType
            this.activeStep = 2
          } else {
            this.handleCallback({
              isError: true,
              errorMessage: result.msg || '失败!'
            })
          }
        })
      },
      async handleSearchRoom() { // 完成接待
        if (!this.form.oldRoomNo) {
          this.$toast('请输入房号')
          return
        }
        if (this.form.oldRoomNo.length < 3) {
          this.$toast('请输入至少3位房号')
          return
        }
        try {
          this.isLoading = true
          await this.$store.dispatch('base/SetLoading', true)
          // let { roomSearchText } = this
          let { dcProjectId } = this.projectInfo
          let response = await getLastRentAPI({
            projectId: dcProjectId,
            roomNo: this.form.oldRoomNo
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.roomList = result.data
            this.showRoom = true
            if (this.roomList.length == 0) {
              this.$toast('无搜索结果')
            }
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '失败!'
          })
        } finally {
          this.isLoading = false
          await this.$store.dispatch('base/SetLoading', false)
        }
      },
      handleSelectRoom(room) {
        console.log(room, 'room')
        this.searchedRoom = { ...room }
        this.form.oldRoomNo = room.roomNo
        // this.roomNo = room.roomNo
        this.form.tenantName = room.tenantName
        this.form.oldHouseDeposit = room.deposit
        this.form.newHouseDeposit = room.deposit
        this.form.oldRoomContarctTime = this.dateFilter(room.startDate, '-', 'YYYY-MM-DD') + '~' + this.dateFilter(room.endDate, '-', 'YYYY-MM-DD')
        if (room.contractType === '续签') {
          this.newRoomMinDate = new Date(dayjs(room.startDate).format('YYYY/MM/DD'))
        } else {
          this.newRoomMinDate = new Date(dayjs(room.startDate).add(1, 'day').format('YYYY/MM/DD'))
        }
        this.newRoomMaxDate = new Date(dayjs(room.endDate).format('YYYY/MM/DD'))
        // this.contractId = room.contractId
        // this.mobile = room.mobile
        getQueryWaterElectricityAPI({ roomId: room.roomId }).then(res => {
          // this.$set(this.roomInfo, this.roomInfo.oldRoomWaterFee, res.data.data.waterPaidAmount)
          this.form.oldRoomWaterFee = res.data.data.waterPaidAmount
          this.form.oldRoomWaterFeeShould = res.data.data.waterTotalAmount
          this.form.waterFeeLeft = res.data.data.waterReturnAmount
          this.form.oldRoomElectricFee = res.data.data.powerPaidAmount
          this.form.oldRoomElectricFeeShould = res.data.data.powerTotalAmount
          this.form.electricFeeLeft = res.data.data.powerReturnAmount
          console.log(res.data.data, 'res---')
        })
        this.showRoom = false
      },
      hourFilter(type, options) {
        if (type === 'hour') {
          // 验房时间是早9晚6
          return options.filter((option) => ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18'].includes(option))
        }
        return options
      },
      async newRoomStayInTimeConfirm(date) {
        try {
          this.isLoading = true
          await this.$store.dispatch('base/SetLoading', true)
          let { dcProjectId } = this.projectInfo
          getHouseListAPI({
            startDate: dayjs(date).format('YYYY-MM-DD'),
            endDate: dayjs(this.searchedRoom.endDate).format('YYYY-MM-DD'),
            roomTypeName: '',
            projectId: dcProjectId
          }).then(res => {
            let result = res.data
            this.newRoomList = result.data
            // console.log('house', res.data)
          }).finally(async () => {
            this.isLoading = false
            await this.$store.dispatch('base/SetLoading', false)
          })
          this.newRoomStayInTimeShow = false
          this.form.newRoomStayInTime = this.dateFilter(date, '-', 'YYYY-MM-DD')
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '失败!'
          })
        }
      },
      newRoomWaterMeterTimeConfirm(date) {
        this.newRoomWaterMeterTimeShow = false
        this.form.newRoomWaterMeterTime = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')
      },
      newRoomElectricMeterTimeConfirm(date) {
        this.newRoomElectricMeterTimeShow = false
        this.form.newRoomElectricMeterTime = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')
      },
      oldRoomElectricMeterTimeConfirm(date) {
        this.oldRoomElectricMeterTimeShow = false
        this.form.oldRoomElectricMeterTime = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')
      },
      oldRoomWaterMeterTimeConfirm(date) {
        this.oldRoomWaterMeterTimeShow = false
        this.form.oldRoomWaterMeterTime = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')
      },
      // 保证金差额结算
      extraDepositCompute() {
        let {
          oldHouseDeposit,
          newHouseDeposit
        } = this.form
        if (oldHouseDeposit && newHouseDeposit) {
          if (Number(newHouseDeposit) < Number(oldHouseDeposit)) {
            this.$toast('新房源保证金不能小于原房源')
            this.$set(this.form, 'newHouseDeposit', oldHouseDeposit)
            this.$set(this.form, 'extraDeposit', 0)
          } else {
            let sum = this.floatSub(newHouseDeposit, oldHouseDeposit)
            this.$set(this.form, 'extraDeposit', sum)
          }
        }
      },
      // 水费结余计算
      waterFeeLeftCompute() {
        let {
          oldRoomWaterFee,
          oldRoomWaterFeeShould
        } = this.form
        if (oldRoomWaterFee && oldRoomWaterFeeShould) {
          let sum = this.floatSub(oldRoomWaterFee, oldRoomWaterFeeShould)
          this.$set(this.form, 'waterFeeLeft', sum)
        }
      },
      // 电费结余计算
      electricFeeLeftCompute() {
        let {
          oldRoomElectricFee,
          oldRoomElectricFeeShould
        } = this.form
        if (oldRoomElectricFee && oldRoomElectricFeeShould) {
          let sum = this.floatSub(oldRoomElectricFee, oldRoomElectricFeeShould)
          this.$set(this.form, 'electricFeeLeft', sum)
        }
      },
      // 小数相减通用方法
      floatSub(arg1, arg2) {
        let r1, r2, m, n
        try {
          r1 = arg1.toString().split('.')[1].length
        } catch (e) {
          r1 = 0
        }
        try {
          r2 = arg2.toString().split('.')[1].length
        } catch (e) {
          r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        // 动态控制精度长度
        n = (r1 >= r2) ? r1 : r2
        return ((arg1 * m - arg2 * m) / m).toFixed(n)
      },
      handleApply: debounce(function() {
        // console.log(this.form)
        const priceLimit = /^([\-\+]?[0-9]+[\d]*(.[0-9]{1,2})?)$/
        const numReg = /^[0-9]+([.]{1}[0-9]+){0,1}$/
        // const numNegReg = /^(-)?[0-9]+([.]{1}[0-9]+){0,1}$/
        const intReg = /^-?[0-9]\d*$/

        if (!this.form.oldRoomNo) {
          this.$toast('请选择房号')
          return
        }
        if (!this.form.newRoomStayInTime) {
          this.$toast('新房源入住日期不能为空')
          return
        }
        if (!this.newRoomCode) {
          this.$toast('新房源不能为空')
          return
        }
        if (!this.form.extraDeposit) {
          this.$toast('保证金差额不能为空')
          return
        }
        if (!this.form.newRoomRentFeeMonth || this.form.newRoomRentFeeMonth == 0) {
          this.$toast('新房源每月综合服务费应大于0')
          return
        }
        if (!intReg.test(this.form.newRoomRentFeeMonth)) {
          this.$toast('新房源每月综合服务费请输入整数')
          return
        }
        if (!this.form.newRoomElectricQuantity) {
          this.$toast('新房源电表度数不能为空')
          return
        }
        if (!numReg.test(this.form.newRoomElectricQuantity)) {
          this.$toast('度数请输入整数或小数')
          return
        }
        if (!this.form.newRoomElectricMeterTime) {
          this.$toast('新房源电表抄表时间不能为空')
          return
        }
        if (!this.form.newRoomWaterQuantity) {
          this.$toast('新房源水表度数不能为空')
          return
        }
        if (!numReg.test(this.form.newRoomWaterQuantity)) {
          this.$toast('度数请输入整数或小数')
          return
        }
        if (!this.form.newRoomWaterMeterTime) {
          this.$toast('新房源水表抄表时间不能为空')
          return
        }
        if (!this.form.damagesFee) {
          this.$toast('资产破损赔偿费用不能为空')
          return
        }
        if (this.form.damagesFee) {
          if (this.form.damagesFee < 0) {
            this.$toast('资产破损赔偿费用不能为负数')
            return
          }
          if (!priceLimit.test(this.form.damagesFee)) {
            this.$toast('资产破损赔偿费用请输入数字，支持最多2位小数')
            return
          }
        }
        if (Number(this.form.damagesFee) > 0) {
          if (!this.form.damagesFeeRemark) {
            this.$toast('资产破损赔偿费用备注不能为空')
            return
          }
        }
        if (!this.form.otherFee) {
          this.$toast('额外增值服务费用不能为空')
          return
        }
        if (this.form.otherFee) {
          if (this.form.otherFee < 0) {
            this.$toast('额外增值服务费用不能为负数')
            return
          }
          if (!priceLimit.test(this.form.otherFee)) {
            this.$toast('额外增值服务费用请输入数字，支持最多2位小数')
            return
          }
        }
        if (Number(this.form.otherFeeRemark) > 0) {
          if (!this.form.otherFeeRemark) {
            this.$toast('额外增值服务费用备注不能为空')
            return
          }
        }
        if (!this.form.cleanFee) {
          this.$toast('保洁收费不能为空')
          return
        }
        if (this.form.cleanFee) {
          if (this.form.cleanFee < 0) {
            this.$toast('保洁收费不能为负数')
            return
          }
          if (!priceLimit.test(this.form.cleanFee)) {
            this.$toast('保洁收费请输入数字，支持最多2位小数')
            return
          }
        }
        if (Number(this.form.cleanFeeRemark) > 0) {
          if (!this.form.cleanFeeRemark) {
            this.$toast('保洁收费备注不能为空')
            return
          }
        }
        if (!this.form.oldRoomElectricQuantity) {
          this.$toast('旧房源电表度数不能为空')
          return
        }
        if (!numReg.test(this.form.oldRoomElectricQuantity)) {
          this.$toast('度数请输入整数或小数')
          return
        }
        if (!this.form.oldRoomElectricMeterTime) {
          this.$toast('旧房源电表抄表时间不能为空')
          return
        }
        if (!this.form.oldRoomWaterQuantity) {
          this.$toast('旧房源水表度数不能为空')
          return
        }
        if (!numReg.test(this.form.oldRoomWaterQuantity)) {
          this.$toast('度数请输入整数或小数')
          return
        }
        if (!this.form.oldRoomWaterMeterTime) {
          this.$toast('旧房源水表抄表时间不能为空')
          return
        }
        if (!this.oldRoomElectricMeterImg || !this.oldRoomElectricMeterImg[0] || !this.oldRoomElectricMeterImg[0].fileUrl) {
          this.$toast('旧房源电表抄表图片不能为空')
          return
        }
        if (!this.oldRoomWaterMeterImg || !this.oldRoomWaterMeterImg[0] || !this.oldRoomWaterMeterImg[0].fileUrl) {
          this.$toast('旧房源水表抄表图片不能为空')
          return
        }
        if (!this.form.electricFeeLeft) {
          this.$toast('电费结余不能为空')
          return
        }
        if (!priceLimit.test(this.form.electricFeeLeft)) {
          this.$toast('金额请输入数字，支持最多2位小数')
          return
        }
        if (!this.form.waterFeeLeft) {
          this.$toast('水费结余不能为空')
          return
        }
        if (!priceLimit.test(this.form.waterFeeLeft)) {
          this.$toast('金额请输入数字，支持最多2位小数')
          return
        }
        if (!this.form.exchangeRoomCause) {
          this.$toast('换房原因不能为空')
          return
        }
        // if (!this.bankName) {
        //   this.$toast('请输入账号名')
        //   return
        // }
        // const bankNameReg = /[^\u4E00-\u9FA5]/
        // if (bankNameReg.test(this.bankName)) {
        //   this.$toast('账号名只能输入中文')
        //   return
        // }
        // if (!this.bankAccount) {
        //   this.$toast('请输入账号')
        //   return
        // }
        // const bankAccountReg = /[^\w\.\/]/
        // if (bankAccountReg.test(this.bankAccount)) {
        //   this.$toast('账号只能输数字及英文字母')
        //   return
        // }
        // if (!this.bankDeposit) {
        //   this.$toast('请输入开户行')
        //   return
        // }
        // const bankDepositReg = /[^\u4E00-\u9FA5]/
        // if (bankDepositReg.test(this.bankDeposit)) {
        //   this.$toast('开户行只能输入中文')
        //   return
        // }
        // if (!(this.reason1.length > 0 || this.reason2.length > 0 || this.reason3.length > 0 || this.otherReason)) {
        //   this.$toast('退房原因不能为空')
        //   return
        // }
        // if (!this.date2) {
        //   this.$toast('上门验房时间不能为空')
        //   return
        // }
        // if (new Date(this.date1).getTime() < new Date(this.date2).getTime()) {
        //   this.$toast('离店时间不能早于验房时间')
        //   return
        // }
        // this.$dialog.confirm({
        //   title: '提示',
        //   message: '是否确认？',
        //   confirmButtonColor: '#D8B084'
        // }).then(async () => {
        //   // on confirm
        //   await this.submitCheckOutApplyData()
        // }).catch(() => {
        //   // on cancel
        // })
        let params = { ...this.form }
        params.oldRoomId = this.searchedRoom.roomId
        params.tenantId = this.searchedRoom.tenantId
        params.oldRoomNo = this.searchedRoom.roomNo
        params.dcContractId = this.searchedRoom.dcContractId
        params.tenantMobile = this.searchedRoom.tenantMobile
        params.deposit = this.searchedRoom.deposit
        const newRoom = this.newRoomList.find(item => item.houseCode === this.newRoomCode)
        params.newRoomId = newRoom.houseCode
        params.newRoomNo = newRoom.roomNo
        params.oldRoomRentPrice = this.searchedRoom.rentPrice
        params.oldRoomStartDate = this.searchedRoom.startDate
        params.oldRoomEndDate = this.searchedRoom.endDate
        params.oldSignElectricQuantity = this.searchedRoom.electricQuantity
        params.oldSignWaterQuantity = this.searchedRoom.waterQuantity
        params.oldRoomElectricMeterImgUrl = this.oldRoomElectricMeterImg[0].fileUrl
        params.oldRoomWaterMeterImgUrl = this.oldRoomWaterMeterImg[0].fileUrl
        params.damagesFee = this.form.damagesFee || 0
        params.otherFee = this.form.otherFee || 0
        params.cleanFee = this.form.cleanFee || 0
        params.damagesFeeRemark = this.form.damagesFeeRemark || '无'
        params.otherFeeRemark = this.form.otherFeeRemark || '无'
        params.cleanFeeRemark = this.form.cleanFeeRemark || '无'
        params.newRoomServiceFeeMonth = 0

        // console.log(params)
        this.submitForm = { ...params }
        calculateFeeAPI(params).then(res => {
          const result = res.data
          if (result.code == '10000') {
            this.roomInfo = result.data
            this.activeStep = 2
          } else {
            this.handleCallback({
              isError: true,
              errorMessage: result.msg
            })
          }
        })
      }, 200),
      handleSubmit() {
        if (!this.form.signType) {
          this.$toast('请选择签署方式')
          return
        }

        this.$dialog.confirm({
          title: '提示',
          message: `您确定选择${this.form.signType == 1 ? '线上签署' : '线下签署'}`,
          confirmButtonColor: '#D8B084'
        }).then(async () => {
          // on confirm
          this.submitData()
        }).catch(() => {
          // on cancel
        })
      },
      async submitData() { // 完成接待
        try {
          this.isLoading = true
          await this.$store.dispatch('base/SetLoading', true)
          let { dcProjectId, projectName } = this.projectInfo
          const params = { ...this.submitForm, signType: this.form.signType }
          params.tenantMobile = this.roomInfo.tenantMobile
          params.projectId = dcProjectId
          params.projectName = projectName
          params.firstMonthPayAmount = this.roomInfo.firstMonthPayAmount
          params.balanceFee = this.roomInfo.balanceFee
          params.remainingFee = this.roomInfo.remainingFee
          params.exchangeCost = this.roomInfo.exchangeCost
          params.exchangeServiceFee = this.roomInfo.exchangeServiceFee || 0
          params.exchangeRuleFee = this.roomInfo.exchangeRuleFee || 0
          params.deposit = this.roomInfo.deposit
          params.isZheKou = this.roomInfo.isZheKou
          params.zheKouAmount = this.roomInfo.zheKouAmount
          params.appUserId = this.roomInfo.appUserId

          let response = await submitAPI(params)
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.$toast({
              message: '提交成功！',
              onClose: () => {
                this.$router.go(-1)
              }
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '提交失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '提交失败!'
          })
        } finally {
          this.isLoading = false
          await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async handleCallback({
                             isError,
                             errorMessage = undefined
                           }) { // 回调处理
        if (isError) {
          this.$toast(errorMessage || '获取失败!')
        }
      },
      afterFileRead(item, field) {
        let up = this.$toast.loading({ forbidClick: true, duration: 0 })
        console.log('item', item, field)
        imageCompression(item.file, {
          maxSizeMB: 0.2,
          initialQuality: 0.7
        }).then(file => {
          assetsApi.fileUpload(item).then(res => {
            this.$toast.clear()
            const cb = res.data
            if (cb.code === 200) {
              item.fileType = 1
              item.url = cb.data.url
              item.fileUrl = cb.data.url
              // this.form[field] = cb.data.url
              item.status = 'success'
              item.message = '上传成功'
            } else {
              item.status = 'failed'
              item.message = '上传失败'
            }
            this.$toast.clear()
          }).catch(e => {
            this.$toast.clear()
            item.status = 'failed'
            item.message = '上传失败'
          })
        }).catch((err) => {
          console.log(err)
          this.$toast.clear()
          item.status = 'failed'
          item.message = '上传失败'
        })
      }
    },
    filters: {},
    watch: {},
    created() {
      if (this.$route.query.id) {
        this.getDetail(this.$route.query.id)
      }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
